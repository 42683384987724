import React from "react";
import Footer from "../../components/Footer";
import AppBar from "../../components/AppBar";
import Typography from "@material-ui/core/Typography";
import "./index.scss";

export default () => (
  <div>
    <AppBar />
    <div className="section-content terms-of-service">
      <div>
        <Typography gutterBottom variant="h4">
          Upload My Stock Terms of Service
        </Typography>
        <Typography gutterBottom variant="h5" className="title">
          1. Terms
        </Typography>
        <Typography variant="body1">
          By accessing the website at{" "}
          <a href="http://www.uploadmystock.com">
            http://www.uploadmystock.com
          </a>
          , you are agreeing to be bound by these terms of service, all
          applicable laws and regulations, and agree that you are responsible
          for compliance with any applicable local laws. If you do not agree
          with any of these terms, you are prohibited from using or accessing
          this site. The materials contained in this website are protected by
          applicable copyright and trademark law.
        </Typography>
        <Typography gutterBottom variant="h5" className="title">
          2. Use License
        </Typography>
        <Typography variant="body1" component="span">
          <ol type="a">
            <li>
              Permission is granted to temporarily download one copy of the
              materials (information or software) on Upload My Stock's website
              for personal, non-commercial transitory viewing only. This is the
              grant of a license, not a transfer of title, and under this
              license you may not:
              <ol type="i">
                <li>modify or copy the materials;</li>
                <li>
                  use the materials for any commercial purpose, or for any
                  public display (commercial or non-commercial);
                </li>
                <li>
                  attempt to decompile or reverse engineer any software
                  contained on Upload My Stock's website;
                </li>
                <li>
                  remove any copyright or other proprietary notations from the
                  materials; or
                </li>
                <li>
                  transfer the materials to another person or "mirror" the
                  materials on any other server.
                </li>
              </ol>
            </li>
            <li>
              This license shall automatically terminate if you violate any of
              these restrictions and may be terminated by Upload My Stock at any
              time. Upon terminating your viewing of these materials or upon the
              termination of this license, you must destroy any downloaded
              materials in your possession whether in electronic or printed
              format.
            </li>
          </ol>
        </Typography>
        <Typography gutterBottom variant="h5" className="title">
          3. Disclaimer
        </Typography>
        <Typography variant="body1" component="span">
          <ol type="a">
            <li>
              The materials on Upload My Stock's website are provided on an 'as
              is' basis. Upload My Stock makes no warranties, expressed or
              implied, and hereby disclaims and negates all other warranties
              including, without limitation, implied warranties or conditions of
              merchantability, fitness for a particular purpose, or
              non-infringement of intellectual property or other violation of
              rights.
            </li>
            <li>
              Further, Upload My Stock does not warrant or make any
              representations concerning the accuracy, likely results, or
              reliability of the use of the materials on its website or
              otherwise relating to such materials or on any sites linked to
              this site.
            </li>
          </ol>
        </Typography>

        <Typography gutterBottom variant="h5" className="title">
          4. Limitations
        </Typography>
        <Typography variant="body1">
          In no event shall Upload My Stock or its suppliers be liable for any
          damages (including, without limitation, damages for loss of data or
          profit, or due to business interruption) arising out of the use or
          inability to use the materials on Upload My Stock's website, even if
          Upload My Stock or a Upload My Stock authorized representative has
          been notified orally or in writing of the possibility of such damage.
          Because some jurisdictions do not allow limitations on implied
          warranties, or limitations of liability for consequential or
          incidental damages, these limitations may not apply to you.
        </Typography>
        <Typography gutterBottom variant="h5" className="title">
          5. Accuracy of materials
        </Typography>
        <Typography variant="body1">
          The materials appearing on Upload My Stock's website could include
          technical, typographical, or photographic errors. Upload My Stock does
          not warrant that any of the materials on its website are accurate,
          complete or current. Upload My Stock may make changes to the materials
          contained on its website at any time without notice. However Upload My
          Stock does not make any commitment to update the materials.
        </Typography>
        <Typography gutterBottom variant="h5" className="title">
          6. Links
        </Typography>
        <Typography variant="body1">
          Upload My Stock has not reviewed all of the sites linked to its
          website and is not responsible for the contents of any such linked
          site. The inclusion of any link does not imply endorsement by Upload
          My Stock of the site. Use of any such linked website is at the user's
          own risk.
        </Typography>
        <Typography gutterBottom variant="h5" className="title">
          7. Modifications
        </Typography>
        <Typography variant="body1">
          Upload My Stock may revise these terms of service for its website at
          any time without notice. By using this website you are agreeing to be
          bound by the then current version of these terms of service.
        </Typography>
        <Typography gutterBottom variant="h5" className="title">
          8. Governing Law
        </Typography>
        <Typography variant="body1">
          These terms and conditions are governed by and construed in accordance
          with the laws of California and you irrevocably submit to the
          exclusive jurisdiction of the courts in that State or location.
        </Typography>
      </div>
    </div>
    <Footer />
  </div>
);
